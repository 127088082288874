// Packages
import { Routes, Route } from "react-router-dom"

// Context
import { UserProvider } from 'context/User/';

// Routes
import BookingRoutes from "modules/Bookings/routes"
import PlacementRoutes from "modules/Placements/routes"
import CalendarRoutes from "modules/Calendar/routes"
import SettingRoutes from "modules/Settings/routes"
import CouponRoutes from "modules/Coupons/routes"
import LoginRoutes from "modules/Login/routes";
import ThankYouScreen from "modules/Bookings/screens/Public/ThankYou";
import PublicBookingCreateScreen from "modules/Bookings/screens/Public/Create";
import BookingApproveScreen from "modules/Bookings/screens/Public/Approve"
import StatsRoutes from "modules/Stats/routes";
import UserRoutes from "modules/Users/routes";
import BeaconLoader from 'components/BeaconLoader';


	// Wrapper component for protected routes
const ProtectedRoutes = () => (
	<UserProvider>
		<BeaconLoader>
			<Routes>
				<Route path="/bookings/*" element={<BookingRoutes />} />
				<Route path="/placements/*" element={<PlacementRoutes />} />
				<Route path="/calendar/*" element={<CalendarRoutes />} />
				<Route path="/settings/*" element={<SettingRoutes />} />
				<Route path="/coupons/*" element={<CouponRoutes />} />
				<Route path="/stats/*" element={<StatsRoutes />} />
				<Route path="/users/*" element={<UserRoutes />} />
				<Route path="/*" element={<BookingRoutes />} />
			</Routes>
		</BeaconLoader>
	</UserProvider>
);

const MainRoutes = () => {
	return (
		<Routes>
			{/* Protected Routes */}
			<Route path="/admin/*" element={<ProtectedRoutes />} />

			{/* Public Routes (outside of UserProvider) */}
			<Route path="/login/*" element={<LoginRoutes />} />
			<Route path='/thank-you/*' element={<ThankYouScreen />}/>
			<Route path="/approve/*" element={<BookingApproveScreen />}/>
			<Route path='/' element={<PublicBookingCreateScreen />}/>
			
		</Routes>
	);
};

export default MainRoutes;