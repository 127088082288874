// Packages
import { useLayoutEffect, useState } from 'react';

// Context
import { useUser } from "context/User"; 

// Components
import LoadBeacon from './LoadBeacon';

const BeaconLoader = ({children}) => {

    const { user } = useUser();
    const [didLoad, setDidLoad] = useState(false);

    useLayoutEffect(() => {

        if (!didLoad && user) {

            setDidLoad(true);
            LoadBeacon({ displayName: user.name, email: user.email });
        }
        
    }, [didLoad, user]);

    return children;
};

export default BeaconLoader;