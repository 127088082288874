const LoadBeacon = ({ displayName, email }) => {

    (function (e, t, n) {

        function a() {

            var e = t.getElementsByTagName("script")[0];
            var n = t.createElement("script");
            n.type = "text/javascript";
            n.async = true;
            n.src = "https://beacon-v2.helpscout.net";
            e.parentNode.insertBefore(n, e);
        }

        if (
            ((e.Beacon = n = function (t, n, a) {
                e.Beacon.readyQueue.push({ method: t, options: n, data: a });
            }),
            (n.readyQueue = []),
            "complete" === t.readyState)
        )
            return a();
        e.attachEvent ? e.attachEvent("onload", a) : e.addEventListener("load", a, false);
        
    })(window, document, window.Beacon || function () {});

    window.Beacon('init', '383f33ea-64bd-43c5-9d93-1609ae015053');

    // Identifying is optional:
    window.Beacon('identify', {
        name: displayName,
        email: email,
    });
};

export default LoadBeacon;