// Packages
import { useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types'
import { Link, useNavigate } from "react-router-dom"; 

// Context
import { useUser } from "context/User"; 
import { useSnackbar } from 'context/Snackbar';
import axios from "axios";

// Components
import BTDButton from "components/BTDUI/BTDButton";
import Footer from 'components/Footer';
import Container from "components/BTDUI/Container";

// other
import './styles.css';
import logo from 'assets/anza-logo.png';
import config from 'other/config';

const AdminLayout = ({ children }) => {

    const navigate = useNavigate();

	const { showSnackbar } = useSnackbar();
	const { user } = useUser();

	const token = localStorage.getItem('access-token');

    const [menuActive, setMenuActive] = useState(window.innerWidth > 840);

    const sidebarRef = useRef(null);

    useEffect(() => {

        const handleResize = () => {
        
            setMenuActive(window.innerWidth > 840);
        };

        const handleClickOutside = (event) => {

            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {

                setMenuActive(false);
            }
        };

        document.addEventListener('click', handleClickOutside);
        window.addEventListener('resize', handleResize);
    
        return () => {

            window.removeEventListener('resize', handleResize);
            document.removeEventListener('click', handleClickOutside);
        };
    }, []); 

	const logout = async () => {

		try {
			const response = await axios.post(config.API_URL + '/logout', {}, {
				headers: {
					Authorization: 'Bearer ' + token
				}});
	
			if (response.status >= 200 && response.status < 300) {

				localStorage.removeItem('access-token');

				showSnackbar('Logged out', 'info')
				
				navigate('/login');
			}
			
		} catch (error) {

			console.error('Logout failed', error);
	
			if (error.response.status === 401) {
				
				showSnackbar('Unauthorised', 'error');

				navigate('/login');

			} else {

				showSnackbar(error.response.message, 'error')
			}
		}
	}

    return(
        <>
        <div className="AdminLayout">

            {(menuActive || window.innerWidth >= 840) ?
                <div className={`AdminLayout__sidebar`} ref={sidebarRef}>

                    <div className="AdminLayout__sidebar__logo">
                        <img src={logo} alt="Logo for Anza"/>
                    </div>

                    <nav className="AdminLayout__sidebar__nav">
                        <ul className="AdminLayout__sidebar__nav__list">
                            <li className="AdminLayout__sidebar__nav__list__item"><Link to="/admin/bookings">Bookings</Link></li>
                            <li className="AdminLayout__sidebar__nav__list__item"><Link to="/admin/placements">Placements</Link></li>
                            <li className="AdminLayout__sidebar__nav__list__item"><Link to="/admin/calendar">Calendar</Link></li>
                            <li className="AdminLayout__sidebar__nav__list__item" style={{marginBottom: '60px'}}><Link to="/admin/coupons">Coupons</Link></li>
                            <li className="AdminLayout__sidebar__nav__list__item"><Link to="/admin/settings">Settings</Link></li>
                            <li className="AdminLayout__sidebar__nav__list__item"><Link to="/admin/users">Users</Link></li>
                        </ul>
                    </nav>

                </div>
            :
                <div className={`AdminLayout__menuTab`} onClick={() => setMenuActive(true)}>MENU</div>
            }


            <div className="AdminLayout__wrapper">

                <header className="AdminLayout__header">
                    <Container>
                    <span className="AdminLayout__header__linkDiv"><a href="/" className="AdminLayout__header__linkDiv__link" target="_blank">Booking Form</a></span>
                    {user &&
                        <div className="AdminLayout__header__user">
                            <p className="AdminLayout__header__user__name">{user.name}</p>
                                <BTDButton
                                    label="Logout"
                                    onClick={() => {logout()}}
                                    size="small"
                                />
                        </div>
                    }
                    </Container>
                </header>

                <div className="AdminLayout__body">
                    <Container>
                        {children}
                    </Container>
                </div>
                
                <Footer />

            </div>

        </div>

        </>
    )
}

AdminLayout.propTypes = {
    children: PropTypes.node
}

AdminLayout.defaultProps = {
    children: <></>
}

export default AdminLayout;